import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Weekend w Gdańsku" />
      <section className="top" id="top">
        <div className="wrap">
          <h1>Weekend w Gdańsku!</h1>
        </div>
      </section>
      <section className="about" id="o-nas">
        <div className="wrap">
          <div className="title-block negative-margin-left">
            <h3>Poznajmy się</h3>
            <h2>Jesteśmy biurem podróży z Gdańska</h2>
          </div>
          <div>
            <p>
              Jako firma rodzinna od ponad 20 lat organizujemy wycieczki
              szkolne, grupowe oraz dla gości indywidualnych. Dzięki zdobytemu
              doświadczeniu staliśmy się specjalistami w zakresie turystyki w
              Polsce i Europie wschodniej. Oferujemy szeroką gamę wyjazdów do
              wielu miast i zakątków Polski oraz świata.
            </p>
          </div>
          <div className="two-col-span">
            <p>
              Gdańsk to miasto z bogatą historią, ale także z nowoczesnym
              charakterem. Stare Miasto z przepięknymi zabytkami zachwyca o
              każdej porze roku. Natomiast restauracje z różnych kuchni świata,
              przystań jachtowa oraz bajeczne widoki gwarantują jakość udanego
              wypoczynku. Dzięki rozwojowi Gdańska, możemy urozmaicić pobyt
              naszym klientom jeszcze bardziej.
            </p>
          </div>
          <div>
            <h3>Razem z nami</h3>
            <h2>Poznasz Trójmiasto z innej perspektywy</h2>
            <p>
              Jeżeli szukacie pomysłu na to, jak spędzić ciekawie weekend, aby
              odpocząć i poznać nowe miejsca to dobrze trafiliście.
              Przygotowaliśmy kilka najciekawszych naszym zdaniem propozycji
              spędzenia weekendu w Gdańsku i okolicach. Dzięki nam zobaczycie
              Trójmiasto z innej perspektywy, być może odkryjecie miejsca, które
              nie są opisane w przewodnikach turystycznych.
            </p>
          </div>
          <div className="title-block negative-margin-right about-bg-img"></div>
        </div>
      </section>
      <section className="trips" id="propozycje">
        <div className="wrap">
          <div className="title-block negative-margin-left">
            <h3>Nasze propozycje na</h3>
            <h2>Weekend w Gdańsku</h2>
          </div>
          <Link to="/aktywny-weekend-w-gdansku" className="single-trip">
            <div
              className="image-section"
              style={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(0,0,0,0.75) 1%, rgba(0,0,0,0.0) 100%), url(/static/aktywny-weekend.jpg)",
              }}
            >
              <div className="trip-description">
                <h4>Aktywny weekend w Gdańsku</h4>
                <p>
                  Oferta jest skierowana przede wszystkim do tych, którzy
                  poszukują aktywnej i dobrej zabawy w czasie weekendu. Jest to
                  doskonały wybór dla osób, które chcą aktywnie spędzić wolny
                  czas.{" "}
                </p>
                <div className="button">Czytaj więcej</div>
              </div>
            </div>
          </Link>
          <Link to="/historyczny-weekend-w-gdansku" className="single-trip">
            <div
              className="image-section"
              style={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(0,0,0,0.75) 1%, rgba(0,0,0,0.0) 100%), url(/static/historyczny-weekend.jpg)",
              }}
            >
              <div className="trip-description">
                <h4>Historyczny weekend w Gdańsku</h4>
                <p>
                  Bogata historia miasta Gdańska jest naszą dumą. Zapraszamy do
                  odwiedzenia nas i poznania popularnych atrakcji historycznych,
                  w tym tych, znanych na skalę światową. Zobaczycie tak zwaną
                  Drogę Królewską z jej pięknym Ratuszem, Dworem Artusa...
                </p>
                <div className="button">Czytaj więcej</div>
              </div>
            </div>
          </Link>
          <Link
            to="/kulinarny-weekend-w-gdansku"
            className="single-trip double-col"
          >
            <div
              className="image-section"
              style={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(0,0,0,0.75) 1%, rgba(0,0,0,0.0) 100%), url(/static/kulinarny-weekend.jpg)",
              }}
            >
              <div className="trip-description">
                <h4>Kulinarny weekend w Gdańsku</h4>
                <p>
                  Serdecznie zapraszamy na weekend degustacyjny do Gdańska.
                  Odwiedzicie najlepsze restauracje w mieście z oryginalnymi
                  polskimi potrawami w towarzystwie uroczych widoków nad Zatoką
                  Gdańską.
                </p>
                <div className="button">Czytaj więcej</div>
              </div>
            </div>
          </Link>
          <Link to="/nadmorski-weekend-w-gdansku" className="single-trip">
            <div
              className="image-section"
              style={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(0,0,0,0.75) 1%, rgba(0,0,0,0.0) 100%), url(/static/nadmorski-weekend.jpg)",
              }}
            >
              <div className="trip-description">
                <h4>Nadmorski weekend w Gdańsku</h4>
                <p>
                  Wychowywanie się nad Zatoką Gdańską sprawiło, że bardzo
                  kochamy wodę. Cieszymy się, że możemy podzielić się z Wami
                  naszym najlepszym doświadczeniem związanym ze sportem wodnym.
                </p>
                <div className="button">Czytaj więcej</div>
              </div>
            </div>
          </Link>
          <Link to="/rodzinny-weekend-w-gdansku" className="single-trip">
            <div
              className="image-section"
              style={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(0,0,0,0.75) 1%, rgba(0,0,0,0.0) 100%), url(/static/rodzinny-weekend.jpg)",
              }}
            >
              <div className="trip-description">
                <h4>Rodzinny weekend w Gdańsku</h4>
                <p>
                  Jeżeli chcecie spędzić przyjemny, rodzinny weekend poza swoim
                  miejscem zamieszkania, to postawcie na Gdańsk. Przede
                  wszystkim zapraszamy na rodzinny dzień podczas rejsu statkiem.
                </p>
                <div className="button">Czytaj więcej</div>
              </div>
            </div>
          </Link>
          <Link
            to="/kajakowy-weekend-w-gdansku"
            className="single-trip double-col"
          >
            <div
              className="image-section"
              style={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(0,0,0,0.75) 1%, rgba(0,0,0,0.0) 100%), url(/static/kajakowy-weekend.jpg)",
              }}
            >
              <div className="trip-description">
                <h4>Kajakowy weekend w Gdańsku</h4>
                <p>
                  Oferta jest ciekawą alternatywą dla tych, którzy chcą aktywnie
                  spędzić weekend, ale także zobaczyć historyczne miasto i
                  skosztować lokalnej kuchni. Poznacie miasto z innej
                  perspektywy.
                </p>
                <div className="button">Czytaj więcej</div>
              </div>
            </div>
          </Link>
          <Link
            to="/romantyczny-weekend-w-gdansku"
            className="single-trip double-col"
          >
            <div
              className="image-section"
              style={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(0,0,0,0.75) 1%, rgba(0,0,0,0.0) 100%), url(/static/romantyczny-weekend.jpg)",
              }}
            >
              <div className="trip-description">
                <h4>Romantyczny weekend w Gdańsku</h4>
                <p>
                  Z przyjemnością zapraszamy Was na romantyczny weekend w
                  Gdańsku. Ty i Twoja druga połówka możecie spędzić wyjątkowe
                  chwile w pięknym nadmorskim mieście Gdańsk.
                </p>
                <div className="button">Czytaj więcej</div>
              </div>
            </div>
          </Link>

          <Link to="/weekend-na-koncu-polski" className="single-trip">
            <div
              className="image-section"
              style={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(0,0,0,0.75) 1%, rgba(0,0,0,0.0) 100%), url(/static/weekend-na-koncu-polski.jpg)",
              }}
            >
              <div className="trip-description">
                <h4>Weekend na końcu Polski</h4>
                <p>
                  Z przyjemnością przedstawiamy ofertę pełną aktywności na
                  morzu. To idealny pomysł na weekend dla wszystkich lubiących
                  kontakt z wodą i aktywny wypoczynek.
                </p>
                <div className="button">Czytaj więcej</div>
              </div>
            </div>
          </Link>
          <Link to="/rowerowy-weekend-w-gdansku" className="single-trip">
            <div
              className="image-section"
              style={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(0,0,0,0.75) 1%, rgba(0,0,0,0.0) 100%), url(/static/rowerowy-weekend.jpg)",
              }}
            >
              <div className="trip-description">
                <h4>Rowerowy weekend w Gdańsku</h4>
                <p>
                  Gdańsk jest jednym z najbardziej rozwiniętych miast w Polsce
                  pod względem infrastruktury rowerowej. Zapraszamy więc do
                  zwiedzania Gdańska na rowerze. Zobaczycie historyczną część
                  miasta i odwiedzicie miejsca, do których nie można wjechać
                  samochodem.
                </p>
                <div className="button">Czytaj więcej</div>
              </div>
            </div>
          </Link>
          <Link to="/weekend-w-trojmiescie" className="single-trip">
            <div
              className="image-section"
              style={{
                backgroundImage:
                  "linear-gradient(0deg, rgba(0,0,0,0.75) 1%, rgba(0,0,0,0.0) 100%), url(/static/weekend-w-trojmiescie.jpg)",
              }}
            >
              <div className="trip-description">
                <h4>Weekend w Trójmieście</h4>
                <p>
                  Trójmiasto to idealny kierunek na weekendowy wypad. Miasta nie
                  zawiodą Was pod względem ilości ciekawych miejsc do
                  zobaczenia. Długa historia Gdańska pozostawiła po sobie wiele
                  zabytków, które zachwycają architekturą i pięknem.
                </p>
                <div className="button">Czytaj więcej</div>
              </div>
            </div>
          </Link>
          <div className="title-block negative-margin-right">
            <h4>Szyte na miarę</h4>
            <h2>Dobierzemy coś dla Ciebie</h2>
          </div>
        </div>
      </section>
      <section className="spektrum-info">
        <div className="wrap">
        <div>
          <p><strong>Biuro Turystyczne Fifi Travel</strong> Maria Fifielska z siedzibą w Gdańsku korzysta ze wsparcia w postaci usług doradczych w ramach projektu grantowego „SPEKTRUM. Pomorski System Usług Doradczych” finansowanego ze środków Unii Europejskiej z Regionalnego Programu Operacyjnego Województwa Pomorskiego, nr Umowy:</p>

          <p>UDG-SPE.01.2020/344 o powierzenie grantu na Projekt: WNG-SPE.01.2020/344</p>

          <p>Celem ww. projektu jest realizacja specjalistycznych usług doradczych przeciwdziałających negatywnym skutkom pandemii COVID-19.</p>

          <p>Planowane wyniki realizacji projektu: doradztwo w zakresie marketingu, doradztwo w zakresie sprzedaży, audyt marketingowy, badania i analiza rynku.</p>

          <p>
            Wartość projektu:<br/>
            WNG-SPE.01.2020/344 – 34 552,84 zł wkład Funduszy Europejskich 17 276,42 zł
          </p>
          
          <img src={'/static/spektrum.gif'} alt="" />
        </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
